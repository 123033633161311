import { Button, Grid, Paper } from "@mui/material";
import key1 from "../../assets/images/keys1.gif"; // Ensure the correct path to the image
import key2 from "../../assets/images/keys2.gif"; // Ensure the correct path to the image
import key3 from "../../assets/images/keys3.gif"; // Ensure the correct path to the image
import key4 from "../../assets/images/keys4.gif"; // Ensure the correct path to the image
import key5 from "../../assets/images/keys5.gif"; // Ensure the correct path to the image
import nyscBg from "../../assets/images/nyscbg.png"; // Ensure the correct path to the image

import { useState } from "react";
import nyscRight from "../../assets/images/nyscright.png"; // Ensure the correct path to the image
import Animator from "../../components/Aminator/Animator";
import { useIsMobile } from "../../components/hooks/useIsMobile";
import MainLayouts from "../../layout/MainLayouts";
import SignupForm from "./Components/SignUpForm";
function Nysc() {
  const mobile = useIsMobile();
  const keys = [
    {
      title: "Hands-on Data Skills",
      img: key1,
      info: "Master essential tools like Excel, Python, SQL, and Power BI through practical, hands-on experience.",
    },
    {
      title: "Continuous Learning",
      img: key2,
      info: "Stay at the forefront of industry standards with additional resources like workshops, industry insights, and soft skill sessions.",
    },
    {
      img: key3,
      title: "Data-Driven Decision Making",
      info: "Learn how to transform data into actionable insights, making you a valuable asset for any company.",
    },
    {
      title: "Resume Revamp & Job Preparation",
      img: key4,
      info: "No experience? No problem! We’ll help you craft a standout resume and create projects that showcase your skills. Plus, get expert advice and practice to ace interviews with confidence and land your dream job!",
    },
    {
      title: "Peer-to-peer Mentorship",
      img: key5,
      info: "Grow together with your fellow corps members in a supportive community, keeping each other motivated and accountable.",
    },
    {
      title: "Global Certification Access",
      img: key5,
      info: "Gain internationally recognized certifications that validate your skills and set you apart in the global job market. Open doors to exciting career opportunities worldwide!",
    },
  ];
  const [open, setOpen] = useState(false);
  return (
    <MainLayouts>
      <SignupForm
        open={open}
        onSubmit={() => {
          setOpen();
        }}
        handleClose={() => {
          setOpen(false);
        }}
      />
      <div
        style={{
          marginTop: mobile ? 60 : 150,
          paddingTop: mobile ? 100 : 0,
          overflow: mobile ? "hidden" : "",
        }}
      >
        <header
          style={{
            background: `url(${nyscBg})`, // Use the correct variable here
            backgroundAttachment: mobile ? "" : "fixed",
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            position: "relative",
          }}
        >
          <div className="absolute top-0 bottom-0 right-0 left-0 bg-[rgba(0,0,0,0.5)]"></div>
          <div className="innersection">
            <Grid container spacing={12} sx={{ minHeight: "90vh" }}>
              <Grid item md={6} sm={12} xs={12}>
                <div
                  style={{
                    position: "relative",
                    zIndex: 2,
                    height: "100%",
                    flexDirection: "column",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "flex-start",
                  }}
                >
                  <Animator delay={300} timeout={300}>
                    <h1
                      style={{
                        fontWeight: 600,
                        color: "#d14524",
                      }}
                    >
                      Let’s HELP YOU
                    </h1>
                  </Animator>
                  <Animator delay={500} timeout={500}>
                    <h1
                      style={{
                        fontWeight: 600,
                        fontSize: mobile ? 37 : 41,
                        color: "#fff",
                        marginTop: 10,
                      }}
                    >
                      Make the best of your service year
                      {/* <span
                        style={{
                          color: "#d14524",
                        }}
                      >
                        {" "}
                        E1X:
                      </span>{" "}
                      Empowering NYSC Corps Members */}
                    </h1>
                  </Animator>
                  <Animator delay={600} timeout={600}>
                    <p style={{ color: "#FFFFFF", marginTop: 10 }}>
                      E1x Academy is here to equip NYSC corps members like you
                      with the skills needed to step confidently into the job
                      market, right from your service year. Prepare for a
                      seamless transition to the workforce—no more walking
                      around with your CV after NYSC!
                    </p>
                  </Animator>
                  <Animator delay={800} timeout={800}>
                    <Button
                      onClick={() => {
                        setOpen(true);
                      }}
                      style={{
                        background: "#d14524",
                        color: "#fff",
                        fontSize: 14,
                        padding: "15px 50px",
                        marginTop: 20,
                      }}
                    >
                      Get Started
                    </Button>
                  </Animator>
                </div>
              </Grid>
              <Grid item md={6} sm={12} xs={12}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    position: "relative",
                    // marginBottom: "-50%",
                  }}
                >
                  <Animator delay={300} timeout={300}>
                    <div
                      style={{
                        minHeight: mobile ? "400px" : "80vh",
                        background: "#D9D9D94D",
                        marginBottom: "-50%",
                        width: "90%",
                        borderRadius: 18,
                        position: "relative",
                        zIndex: 1,
                      }}
                    ></div>
                  </Animator>
                  <Animator
                    variant="slide"
                    direction="left"
                    delay={500}
                    timeout={500}
                  >
                    <div
                      style={{
                        borderRadius: 18,
                        background: "#D9D9D999",
                        position: "absolute",
                        zIndex: 2,
                        minHeight: mobile ? "400px" : "80vh",
                        top: 0,
                        // width:""
                        bottom: 0,
                        left: 20,
                        right: 20,
                        marginBottom: "-20%",
                      }}
                    />
                  </Animator>
                  <Animator
                    variant="slide"
                    direction="left"
                    delay={700}
                    timeout={700}
                  >
                    <div
                      style={{
                        borderRadius: 18,
                        background: "#D9D9D999",
                        position: "absolute",
                        zIndex: 3,
                        minHeight: mobile ? "400px" : "80vh",
                        top: 0,
                        // width:""
                        bottom: 0,
                        left: 0,
                        right: 40,
                        overflow: "hidden",
                        marginBottom: "-20%",
                      }}
                    >
                      <img
                        src={nyscRight}
                        style={{
                          borderRadius: 18,
                          height: "100%",
                          objectFit: "cover",
                          width: "100%",
                        }}
                      />
                    </div>
                  </Animator>
                </div>
              </Grid>
            </Grid>
          </div>
        </header>
        <section
          className="innersection"
          style={{
            paddingTop: 110,
            paddingBottom: 110,
          }}
        >
          {/* <h1 style={{ color: "#888888", fontSize: 20 }}>Our NYSC program.</h1> */}
          <div
            style={{
              display: "flex",

              justifyContent: "center",
            }}
          >
            <div
              style={{
                width: mobile ? "100%" : "60%",
                textAlign: "center",
                marginTop: 100,
              }}
            >
              <h1 style={{ fontSize: 30, fontWeight: 600 }}>
                Why E1X is Your Best Companion During Our NYSC Program
              </h1>
              <p style={{ color: "#999999", marginTop: 20 }}>
                E1x Academy is an EdTech platform centered on empowering our
                learners, not just delivering lessons. Our specialised data
                analytics courses are crafted to launch you into the rewarding
                field of data analytics. By the end of your service year, our
                program will have equipped you to stand out as a competitive
                talent in the job market.
              </p>
              <h1 style={{ fontSize: 30, fontWeight: 600, marginTop: 80 }}>
                Key Benefits
              </h1>
            </div>
          </div>
          <div
            style={{
              marginTop: 120,
            }}
          >
            <Grid container spacing={6}>
              {keys?.map((cur, i) => (
                <Animator
                  variant="zoom"
                  delay={i === 0 ? 300 : i === 1 ? 500 : i === 2 ? 700 : 800}
                  timeout={500}
                  key={i}
                >
                  <Grid item md={4} sm={12} xs={12}>
                    <Paper className="paper">
                      <img
                        src={cur.img}
                        style={{
                          height: 60,
                          width: "100%",
                          objectFit: "contain",
                        }}
                      />
                      <h1
                        style={{
                          textAlign: "center",
                          fontWeight: 600,
                          fontSize: 18,
                          marginTop: 16,
                        }}
                      >
                        {cur.title}
                      </h1>
                      <p
                        style={{
                          fontSize: 15,
                          textAlign: "center",
                          color: "#999999",
                          marginTop: 10,
                        }}
                      >
                        {cur.info}
                      </p>
                    </Paper>
                  </Grid>
                </Animator>
              ))}
            </Grid>
          </div>
        </section>
        {/* <section
          className="innersection"
          style={{
            paddingBottom: 110,
            overflow: "hidden",
          }}
        >
          <h1 style={{ color: "#888888", fontSize: 20 }}>About E1X and NYSC</h1>
          <div
            style={{
              marginTop: 50,
            }}
          >
            <Grid container spacing={6}>
              <Grid item xs={12} md={5}>
                <Animator variant="zoom" delay={300} timeout={300}>
                  <img
                    src={nyscBg2}
                    style={{
                      width: "100%",
                      height: "100%",
                      borderRadius: 20,
                      objectFit: "cover",
                    }}
                  />
                </Animator>
              </Grid>
              <Grid item xs={12} md={7}>
                <div>
                  <Animator variant="zoom" delay={300} timeout={300}>
                    <h1
                      style={{
                        fontSize: 30,
                        fontWeight: 500,
                      }}
                    >
                      Gain the Skills That Matter Most
                    </h1>
                  </Animator>
                  <Animator variant="zoom" delay={500} timeout={500}>
                    <p
                      style={{
                        fontSize: 15,
                        // fontWeight: 200,
                        color: "#2D2D2D",
                        marginBottom: 60,
                      }}
                    >
                      Learn, grow, and apply cutting-edge skills that will make
                      you stand out
                    </p>
                  </Animator>
                  <div>
                    {[
                      {
                        title: "Data Analysis & Interpretation",
                        info: "Master Excel, SQL, and data visualization techniques to turn raw data into valuable insights.",
                      },
                      {
                        title: "Problem-Solving with Data",
                        info: "Develop a problem-solving mindset using real-world scenarios.",
                      },
                      {
                        title: "Career Development",
                        info: "Receive personalized career guidance, mentorship, and tips on acing interviews.",
                      },
                      {
                        title: "Leadership & Communication",
                        info: "Enhance your leadership potential and communication skills for corporate environments.",
                      },
                    ]?.map((cur, i) => (
                      <Animator
                        variant="zoom"
                        delay={
                          i === 0 ? 300 : i === 1 ? 500 : i === 2 ? 700 : 800
                        }
                        timeout={500}
                        key={i}
                      >
                        <div
                          style={{
                            padding: 15,
                            background: "#f7f7f7",
                            borderRadius: 15,
                            marginBottom: 15,
                          }}
                        >
                          <h1
                            style={{
                              fontSize: 18,
                              fontWeight: 500,
                            }}
                          >
                            {cur.title}
                          </h1>
                          <p
                            style={{
                              fontSize: 14,
                              color: "#666",
                            }}
                          >
                            {cur.info}
                          </p>
                        </div>
                      </Animator>
                    ))}
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
        </section> */}
        <Animator variant="slide" delay={400} timeout={400} direction="left">
          <section
            style={{
              background: "#D15424",
              minHeight: "40vh",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              color: "#fff",
            }}
          >
            <div
              className="innersection"
              style={{
                width: mobile ? "100%" : "30%",
                textAlign: "center",
              }}
            >
              <Animator delay={300} timeout={300}>
                <h1
                  style={{
                    fontSize: mobile ? 30 : 40,
                    fontWeight: 600,
                    marginBottom: 15,
                  }}
                >
                  Sign Up Now
                </h1>
              </Animator>
              <Animator delay={500} timeout={500}>
                <p>
                  You only get one service year—make it count by learning
                  in-demand skills that pave the way for a life-changing career
                  with global opportunities. Stand out from your peers and start
                  building your future today.
                </p>
              </Animator>
              <Animator delay={800} timeout={800}>
                <Button
                  onClick={() => {
                    setOpen(true);
                  }}
                  // fullWidth
                  style={{
                    background: "#fff",
                    color: "#000",
                    fontSize: 14,
                    padding: "15px 50px",
                    marginTop: 20,
                  }}
                >
                  Get Started
                </Button>
              </Animator>
            </div>
          </section>
        </Animator>
      </div>
    </MainLayouts>
  );
}

export default Nysc;
