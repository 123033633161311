import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import client from "../api/client";
import ErrorPage from "../page/ErrorPage";
import { errorMessage } from "../utility";
import VonCourseLiveCard from "./AllVon/VonCourse/VonCourseLiveCard";
import VonCourseOnlineCard from "./AllVon/VonCourse/VonCourseOnlineCard";
import LoadingSpinner from "./LoadingSpinner";

export default function NyscDiscount({ targetRef, type }) {
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [userCoursesState, setUserCoursesState] = useState([]);
  const loadData = async () => {
    setLoading(true);
    try {
      const res = (await client.get("/v2/nysc-discounted-courses")).data;
      setUserCoursesState(res);
    } catch (error) {
      toast.error(errorMessage(error));
    }
    setLoading(false);
  };

  const [toggleState, setToggleState] = useState(1);

  const toggleTab = (index) => {
    setToggleState(index);
  };

  const courses = userCoursesState;

  // Filter courses based on course_id_type
  const sectionMyOneCourses =
    courses?.length > 0
      ? courses?.filter((course) => {
          return course?.type === "online";
        })
      : [];

  const sectionMyTwoCourses =
    courses?.length > 0
      ? courses?.filter((course) => {
          return course?.type === "live";
        })
      : [];
  const [data, setData] = useState({});
  console.log("response ", sectionMyOneCourses);
  useEffect(() => {
    if (type === "live") {
      setToggleState(2);
    } else {
      setToggleState(1);
    }
  }, [type]);
  useEffect(() => {
    loadData();
  }, []);
  return (
    <div
      className=""
      style={{
        minHeight: "20vh",
      }}
    >
      <div className="">
        {loading && userCoursesState?.length === 0 ? (
          <LoadingSpinner />
        ) : error ? (
          <ErrorPage errors={error} />
        ) : (
          <>
            <div
              ref={targetRef}
              className="sm:w-[80%] md:w-[100%] lg:w-1/2 mb-[3.5%]"
            >
              <span
                className={
                  toggleState === 1
                    ? "inline-block w-1/2 px-[10px] py-4 border-t-solid border-t-[3px] border-t-[#d15242] bg-white cursor-pointer text-[#273044] text-center font-medium delay-0 duration-100 ease-in transition-colors text-xs min-[320px]:text-base"
                    : "inline-block w-1/2 px-[10px] py-4 border-t-solid border-t-[3px] border-t-[#f1f1f1] bg-[#f1f1f1] cursor-pointer text-[#273044] text-center font-medium delay-0 duration-100 ease-in transition-colors text-xs min-[320px]:text-base"
                }
                onClick={() => toggleTab(1)}
              >
                Online Courses
              </span>
              <span
                className={
                  toggleState === 2
                    ? "inline-block w-1/2 px-[10px] py-4 border-t-solid border-t-[3px] border-t-[#d15242] bg-white cursor-pointer text-[#273044] text-center font-medium delay-0 duration-100 ease-in transition-colors text-xs min-[320px]:text-base"
                    : "inline-block w-1/2 px-[10px] py-4 border-t-solid border-t-[3px] border-t-[#f1f1f1] bg-[#f1f1f1] cursor-pointer text-[#273044] text-center font-medium delay-0 duration-100 ease-in transition-colors text-xs min-[320px]:text-base"
                }
                onClick={() => toggleTab(2)}
              >
                Live Sessions
              </span>
            </div>

            <div className={toggleState === 1 ? "block" : "hidden"}>
              <div className="flex flex-wrap mb-[5%] gap-[5%] md:gap-[1%]">
                {sectionMyOneCourses?.length === 0 ? (
                  <p>You have not purchased any online courses.</p>
                ) : (
                  sectionMyOneCourses?.map((course, i) => (
                    <VonCourseOnlineCard
                      showPrice
                      key={i}
                      {...course}
                      loading="lazy"
                    />
                  ))
                )}
              </div>
            </div>

            {/* Render section two courses */}

            <div className={toggleState === 2 ? "block" : "hidden"}>
              <div className="flex flex-wrap gap-[5%] md:gap-[1%] mb-4">
                {sectionMyTwoCourses?.length === 0 ? (
                  <p>You have not purchased any Live Sessions.</p>
                ) : (
                  sectionMyTwoCourses?.map((course, i) => (
                    <VonCourseOnlineCard
                      showPrice
                      {...course}
                      loading="lazy"
                      key={i}
                    />
                  ))
                )}
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
}
