import { TextField } from "@mui/material";
import { useGoogleLogin } from "@react-oauth/google";
import { useContext, useEffect, useState } from "react";
import GoogleButton from "react-google-button";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Logo from "../../assets/images/logo.jpeg";
import CircularProgress from "../../components/Generic/CircularProgress";
import { useIsMobile } from "../../components/hooks/useIsMobile";
import { GlobalContext } from "../../context";
import { googleHandler, signInHandler } from "../../context/actions/auth";
import AuthLayout from "../../layout/AuthLayouts";

export default function LoginPage() {
  const mobile = useIsMobile();

  const navigate = useNavigate();
  const {
    authDispatch,
    authState: { data, loggedIn },
  } = useContext(GlobalContext);
  const [googleLoading, setGoogleLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errors, setErrors] = useState({});
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const login = useGoogleLogin({
    onSuccess: (res) => {
      handleGoogleSubmit(res);
    },
  });
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Check if fields are not empty
    if (!email || !password) {
      toast("All field are required ");
      return;
    }

    setLoading(true);
    const res = await signInHandler({ email, password }, authDispatch);
    setLoading(false);
    if (res) {
      setEmail("");
      setPassword("");
      navigate("/");
    }
  };
  const handleGoogleSubmit = async (data) => {
    setGoogleLoading(true);
    const res = await googleHandler(
      {
        access_token: data?.access_token,
      },
      authDispatch
    );

    if (res) {
      navigate("/");
    }
    setGoogleLoading(false);
  };

  useEffect(() => {
    if (loggedIn) {
      navigate("/");
    }
  }, []);

  return (
    <AuthLayout>
      <form onSubmit={handleSubmit} style={{ width: "100%" }}>
        <div
          style={{
            textAlign: mobile ? "center" : "left",
            display: "flex",
            alignItems: mobile ? "center" : "flex-start",
            flexDirection: "column",
            justifyContent: "center",
            width: "100%",
          }}
        >
          <img
            src={Logo}
            style={{
              width: 120,
              height: 60,
              objectFit: "contain",
              marginBottom: 10,
            }}
            alt="Logo"
          />
          <h1
            style={{
              fontSize: mobile ? 18 : 27,
              marginBottom: 20,
              width: "100%",
            }}
          >
            Log in to Continue Learning
          </h1>
          <div style={{ width: "100%" }}>
            <TextField
              required
              label="Email"
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
              InputProps={{
                style: {
                  height: 55,
                  borderRadius: 5,
                  background: "#fff",
                  color: "#000",
                  fontWeight: 300,
                },
              }}
              fullWidth
              style={{ marginBottom: 20 }}
            />
            <TextField
              required
              type="password"
              label="Password"
              value={password}
              onChange={(e) => {
                setPassword(e.target.value);
              }}
              InputProps={{
                style: {
                  height: 55,
                  borderRadius: 5,
                  background: "#fff",
                  color: "#000",
                  fontWeight: 300,
                },
              }}
              fullWidth
              style={{ marginBottom: 20 }}
            />
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <NavLink
                style={{
                  width: mobile ? "60%" : "30%",
                }}
                to="/forgot-password"
                className="text-sm mt-[2%] text-primary-color text-right block cursor-pointer"
              >
                Forgot password
              </NavLink>
            </div>
            {errors.message && <p style={{ color: "red" }}>{errors.message}</p>}
            <button
              type="submit"
              style={{ marginBottom: 15 }}
              className={`w-full py-4 px-5 rounded text-sm bg-primary-color hover:bg-[#884c34] delay-0 duration-150 transition-colors ease-in text-white font-medium mt-[5%]`}
              disabled={loading}
            >
              {loading ? "Loading..." : "LOGIN"}
            </button>

            <div style={{ width: "100%", marginTop: 5 }}>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <GoogleButton
                  label={
                    googleLoading ? (
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          height: "100%",
                          justifyContent: "center",
                        }}
                      >
                        <CircularProgress />
                      </div>
                    ) : (
                      "Sign in with Google"
                    )
                  }
                  onClick={() => {
                    login();
                  }}
                  style={{
                    width: "100%",
                  }}
                />
              </div>
            </div>
            <p className="mt-[3%] text-sm text-center font-medium">
              Don't have an account?{" "}
              <Link to="/sign-up" className="text-primary-color">
                Register Now
              </Link>
              .
            </p>
          </div>
        </div>
      </form>
    </AuthLayout>
  );
}
