import { useContext, useEffect, useRef, useState } from "react";
import { PiListLight } from "react-icons/pi";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { GlobalContext } from "../../../context";
import { logoutHandler } from "../../../context/actions/auth";
import VonLoginModal from "../VonLoginModal/VonLoginModal";
import VonRegistrationModal from "../VonRegistrationModal/VonRegistrationModal";
import Logo from "./assets/logo.png";

export default function VonHeader() {
  const {
    authState: { data, loggedIn },
    authDispatch,
  } = useContext(GlobalContext);
  const user = data?.user || {};
  const [navbar, setNavbar] = useState(false);

  const changeBg = () => {
    if (window.scrollY >= 50) {
      setNavbar(true);
    } else {
      setNavbar(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", changeBg);
  }, [window.screenY]);
  const location = useLocation();

  const isActive = (pathname) => {
    return location.pathname === pathname ? "active" : "";
  };

  const navigate = useNavigate();

  const handleLogout = async () => {
    await logoutHandler(authDispatch);
    navigate("/");
  };

  // Open and Close Register
  const [registerOpen, setRegisterOpen] = useState(false);
  const [loginOpen, setLoginOpen] = useState(false);
  const [currentModal, setCurrentModal] = useState("register");

  const modalRef = useRef(null);

  const handleSpecificDivClickRegister = (e) => {
    e.stopPropagation();
    setRegisterOpen(true);
    setLoginOpen(false);
    setCurrentModal("register");
  };

  const handleSpecificDivClickLogin = (e) => {
    e.stopPropagation();
    setLoginOpen(true);
    setRegisterOpen(false);
    setCurrentModal("login");
  };
  console.log("data", location.pathname);
  // Close the form when clicking outside the modal
  const handleOutsideClick = (e) => {
    if (modalRef.current && !modalRef.current.contains(e.target)) {
      setRegisterOpen(false);
      setLoginOpen(false);
    }
  };

  // Attach the click event listener when the component mounts
  useEffect(() => {
    document.addEventListener("click", handleOutsideClick);

    // Clean up the event listener when the component unmounts
    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, []);

  //   Mobile Menu
  const [menuOpen, setMenuOpen] = useState(false);
  // console.log("nav", setNavbar);
  return (
    <header className="fixed top-0 left-0 right-0  w-full mx-auto z-[80]">
      {/* {!navbar && (
        <div
          className="px-[7%] py-[1%] flex justify-end gap-4 min-[920px]:gap-0 min-[920px]:justify-between bg-[rgb(70,70,70)] text-white text-sm h-[6vh]"
          style={{
            transition: "background-color 2s ease-in, gap 2s ease-in",
          }}
        >
          <div className="hidden gap-2 items-center min-[920px]:flex">
            <div>
              <PiPhoneCallFill />
            </div>
            <Link to="tel:+14433321473">+1 (240) 615-1548</Link>
          </div>
          <div className="hidden gap-2 items-center min-[920px]:flex">
            <div>
              <PiMapPinFill />
            </div>
            <p>1519, Bethlehem Avenue, Dundalk, MD</p>
          </div>
          <div className="hidden gap-2 items-center min-[920px]:flex">
            <div>
              <PiClockFill />
            </div>
            <p>Mon - Sat 8.00 - 18.00</p>
          </div>
          <div className="flex gap-4 items-center">
            <Link
              to="https://www.facebook.com/share/AgqRHyhGV2UVNtqL/?mibextid=qi2Omg"
              className="hover:text-primary-color delay-0 duration-150 ease-in transition-colors"
            >
              <FaFacebookF />
            </Link>
            <Link
              to="https://www.instagram.com/e1xacademy_"
              className="hover:text-primary-color delay-0 duration-150 ease-in transition-colors"
            >
              <FaInstagram />
            </Link>
            <Link
              to="https://x.com/E1XAcademy"
              className="hover:text-primary-color delay-0 duration-150 ease-in transition-colors"
            >
              <FaTwitter />
            </Link>
            <Link
              to="http://wa.me/12029710392"
              className="hover:text-primary-color delay-0 duration-150 ease-in transition-colors"
            >
              <FaWhatsapp />
            </Link>
          </div>
          {loggedIn ? (
            <div className="flex items-center">
              <div className="flex items-center gap-1 pr-2 hover:text-primary-color delay-0 duration-150 ease-in transition-colors">
                <div
                  style={{
                    cursor: "pointer",
                  }}
                >
                  <img
                    src={user?.profile_img || userImage}
                    style={{
                      height: 35,
                      width: 35,
                      objectFit: "cover",
                      borderRadius: "50%",
                    }}
                  />
                </div>
                <Link to="/profile">{user?.firstname}</Link>
              </div>
              <div className="border-l-[1px] border-l-solid border-l-white pl-2 hover:text-primary-color delay-0 duration-150 ease-in transition-colors cursor-pointer">
                <button onClick={handleLogout}>Log out</button>
              </div>
            </div>
          ) : (
            <div className="flex items-center">
              <div className="flex items-center gap-1 pr-2 hover:text-primary-color delay-0 duration-150 ease-in transition-colors">
                <div>
                  <PiUserFill />
                </div>
                <a href="/sign-in">
                  <span
                    className="cursor-pointer"
                    // onClick={handleSpecificDivClickLogin}
                  >
                    Login
                  </span>
                </a>
              </div>
              <div className="border-l-[1px] border-l-solid border-l-white pl-2 hover:text-primary-color delay-0 duration-150 ease-in transition-colors">
                <a href="/sign-up">
                  {" "}
                  <span className="cursor-pointer">Register</span>
                </a>
              </div>
            </div>
          )}
        </div>
      )} */}

      <div
        className={
          navbar
            ? "  sm:flex justify-between items-center px-[7%] py-[1%] bg-[#f1f1f1] delay-0 duration-100 ease-[ease] transition-colors hidden"
            : "sm:flex justify-between items-center px-[7%] py-[1%] bg-[#fff] delay-0 duration-100 ease-[ease] transition-colors hidden"
        }
      >
        <div
          className="innersection"
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
          }}
        >
          <Link to="/" className="sm:w-[15%] lg:w-[10%] ml-[-1%]">
            <img
              src={Logo}
              alt="logo"
              style={{ height: 40, width: 150, objectFit: "contain" }}
            />
          </Link>
          <nav className="flex gap-9   ">
            <Link
              to="/"
              className={
                isActive("/")
                  ? "md:text-xs text-[#D15242] hover:text-primary-color delay-0 duration-150 ease-[ease] transition-colors"
                  : "md:text-xs text-black hover:text-primary-color delay-0 duration-150 ease-[ease] transition-colors"
              }
            >
              Home
            </Link>
            <Link
              to="/about"
              className={
                isActive("/about")
                  ? "md:text-xs text-[#D15242] hover:text-primary-color delay-0 duration-150 ease-[ease] transition-colors"
                  : "md:text-xs text-black hover:text-primary-color delay-0 duration-150 ease-[ease] transition-colors"
              }
            >
              About
            </Link>
            <Link
              to="/courses/categories"
              className={
                isActive("/courses/categories")
                  ? "md:text-xs text-[#D15242] hover:text-primary-color delay-0 duration-150 ease-[ease] transition-colors"
                  : "md:text-xs text-black hover:text-primary-color delay-0 duration-150 ease-[ease] transition-colors"
              }
            >
              Courses
            </Link>
            {/* <Link to="" className={isActive("/careers") ? "pb-2 md:text-lg text-black hover:text-primary-color delay-0 duration-150 ease-[ease] transition-colors relative before:absolute before:bottom-0 before:left-0 before:w-3/5 before:h-[2px] before:bg-primary-color" : "md:text-lg text-black hover:text-primary-color delay-0 duration-150 ease-[ease] transition-colors" }>Careers</Link> */}
            <Link
              to="/contact"
              className={
                isActive("/contact")
                  ? "md:text-xs text-[#D15242] hover:text-primary-color delay-0 duration-150 ease-[ease] transition-colors"
                  : "md:text-xs text-black hover:text-primary-color delay-0 duration-150 ease-[ease] transition-colors"
              }
            >
              Contact Us
            </Link>
            <Link
              to="/nysc"
              className={
                isActive("/nysc")
                  ? "md:text-xs text-[#D15242] hover:text-primary-color delay-0 duration-150 ease-[ease] transition-colors"
                  : "md:text-xs text-black hover:text-primary-color delay-0 duration-150 ease-[ease] transition-colors"
              }
              onClick={() => setMenuOpen(false)}
            >
              Nysc
            </Link>
            <Link
              to="/sponsor"
              className={
                isActive("/contact")
                  ? "md:text-xs text-[#D15242] hover:text-primary-color delay-0 duration-150 ease-[ease] transition-colors"
                  : "md:text-xs text-black hover:text-primary-color delay-0 duration-150 ease-[ease] transition-colors"
              }
              onClick={() => setMenuOpen(false)}
            >
              Sponsor
            </Link>
            <Link
              to="/projects"
              className={
                isActive("/contact")
                  ? "md:text-xs text-[#D15242] hover:text-primary-color delay-0 duration-150 ease-[ease] transition-colors"
                  : "md:text-xs text-black hover:text-primary-color delay-0 duration-150 ease-[ease] transition-colors"
              }
              onClick={() => setMenuOpen(false)}
            >
              Projects
            </Link>
            {user?.is_instructor && (
              <Link
                to="/instructors-upload"
                className={
                  isActive("/instructors-upload")
                    ? "md:text-xs text-[#D15242] hover:text-primary-color delay-0 duration-150 ease-[ease] transition-colors"
                    : "md:text-xs text-black hover:text-primary-color delay-0 duration-150 ease-[ease] transition-colors"
                }
                onClick={() => setMenuOpen(false)}
              >
                Assignment
              </Link>
            )}

            {/* {loggedIn && (
              <Link
                to="/community"
                className={
                  isActive("/community")
                    ? "pb-2 md:text-lg text-white hover:text-primary-color delay-0 duration-150 ease-[ease] transition-colors relative before:absolute before:bottom-0 before:left-0 before:w-3/5 before:h-[2px] before:bg-primary-color"
                    : "md:text-lg text-white hover:text-primary-color delay-0 duration-150 ease-[ease] transition-colors"
                }
              >
                Community
              </Link>
            )} */}
          </nav>{" "}
          {loggedIn ? (
            <div className="flex items-center">
              <div className="flex items-center gap-1 pr-2 hover:text-primary-color delay-0 duration-150 ease-in transition-colors">
                <div
                  style={{
                    cursor: "pointer",
                  }}
                >
                  <img
                    src={user?.profile_img || ""}
                    style={{
                      height: 35,
                      width: 35,
                      objectFit: "cover",
                      borderRadius: "50%",
                    }}
                  />
                </div>
                <Link to="/profile">{user?.firstname}</Link>
              </div>
              <div className="border-l-[1px] border-l-solid border-l-white pl-2 hover:text-primary-color delay-0 duration-150 ease-in transition-colors cursor-pointer">
                <button
                  className={
                    navbar
                      ? "h-4 bg-[#d15242] text-white p-4 rounded-md  text-xs flex items-center justify-center  hover:bg-black hover:text-white"
                      : "h-4 bg-[#d15242] text-white p-4 rounded-md  text-xs flex items-center justify-center  hover:bg-[#f7f7f7] hover:text-black"
                  }
                  onClick={handleLogout}
                >
                  Log out
                </button>
              </div>
            </div>
          ) : location.pathname === "/nysc" ? (
            <div
              style={
                {
                  // marginTop: 20,
                }
              }
              className="flex items-center gap-2"
            >
              {" "}
              <button
                onClick={() => {
                  navigate("/sign-in");
                }}
                className={
                  navbar
                    ? `h-4 bg-[#fff] p-4 rounded-md  text-xs flex items-center justify-center hover:bg-[#d15242] hover:text-white`
                    : `h-4 bg-[#f7f7f7] p-4 rounded-md  text-xs flex items-center justify-center hover:bg-[#d15242] hover:text-white`
                }
              >
                Login
              </button>
            </div>
          ) : (
            <div className="flex items-center gap-2">
              <button
                onClick={() => {
                  navigate("/sign-in");
                }}
                className={
                  navbar
                    ? `h-4 bg-[#fff] p-4 rounded-md  text-xs flex items-center justify-center hover:bg-[#d15242] hover:text-white`
                    : `h-4 bg-[#f7f7f7] p-4 rounded-md  text-xs flex items-center justify-center hover:bg-[#d15242] hover:text-white`
                }
              >
                Login
              </button>
              <button
                onClick={() => {
                  navigate("/sign-up");
                }}
                className={
                  navbar
                    ? "h-4 bg-[#d15242] text-white p-4 rounded-md  text-xs flex items-center justify-center  hover:bg-black hover:text-white"
                    : "h-4 bg-[#d15242] text-white p-4 rounded-md  text-xs flex items-center justify-center  hover:bg-[#f7f7f7] hover:text-black"
                }
              >
                Register
              </button>
            </div>
          )}
        </div>
      </div>
      <div
        className={
          registerOpen || loginOpen
            ? "fixed top-0 left-0 h-screen w-full bg-[#000000a2] z-[60] flex justify-center items-center"
            : "hidden"
        }
      >
        <div
          className="w-[95%] sm:w-[80%] md:w-[60%] lg:w-[45%] xl:w-[35%]"
          ref={modalRef}
        >
          {currentModal === "register" ? (
            <VonRegistrationModal switchToLogin={handleSpecificDivClickLogin} />
          ) : (
            <VonLoginModal
              onClose={() => {
                setLoginOpen(false);
              }}
              switchToRegister={handleSpecificDivClickRegister}
            />
          )}
        </div>
      </div>
      <div className="sm:hidden  bg-[#fff]  md:bg-transparent">
        <div className="bg-[] flex items-center justify-between relative px-[3%] py-[2%]">
          <div className="w-[15%] sm:w-[15%]">
            <img src={Logo} alt="logo" className="w-full sm:w-auto" />
          </div>
          <div>
            <div
              className="text-primary-color text-4xl"
              onClick={() => setMenuOpen(!menuOpen)}
            >
              <PiListLight />
            </div>
          </div>
        </div>
        <nav
          className={
            menuOpen
              ? "h-[90vh] flex flex-col bg-[#F1F1F1] mt-2 transition-all delay-0 duration-150 ease-in-out px-[3%] overflow-auto"
              : "h-0 flex flex-col sm:hidden bg-[#F1F1F1] mt-2  transition-all delay-0 duration-150 ease-in-out px-[3%] overflow-hidden"
          }
        >
          <Link
            to="/"
            className={
              isActive("/")
                ? "py-4 md:text-lg delay-0 duration-150 ease-[ease] transition-colors relative before:absolute before:bottom-0 before:left-0 before:w-full before:h-[2px] before:bg-primary-color"
                : "py-4 md:text-lg delay-0 duration-150 ease-[ease] transition-colors"
            }
            onClick={() => setMenuOpen(false)}
          >
            Home
          </Link>
          <Link
            to="/about"
            className={
              isActive("/about")
                ? "py-4 md:text-lg delay-0 duration-150 ease-[ease] transition-colors relative before:absolute before:bottom-0 before:left-0 before:w-full before:h-[2px] before:bg-primary-color"
                : "py-4 md:text-lg delay-0 duration-150 ease-[ease] transition-colors"
            }
            onClick={() => setMenuOpen(false)}
          >
            About
          </Link>
          <Link
            to="/courses/categories"
            className={
              isActive("/courses/categories")
                ? "py-4 md:text-lg delay-0 duration-150 ease-[ease] transition-colors relative before:absolute before:bottom-0 before:left-0 before:w-full before:h-[2px] before:bg-primary-color"
                : "py-4 md:text-lg delay-0 duration-150 ease-[ease] transition-colors"
            }
            onClick={() => setMenuOpen(false)}
          >
            Courses
          </Link>
          {/* <Link to="" className={isActive("/careers") ? "py-4 md:text-lg delay-0 duration-150 ease-[ease] transition-colors relative before:absolute before:bottom-0 before:left-0 before:w-full before:h-[2px] before:bg-primary-color" : "py-4 md:text-lg delay-0 duration-150 ease-[ease] transition-colors" } onClick={()=> setMenuOpen(false)}>Be An Instructor</Link> */}
          <Link
            to="/contact"
            className={
              isActive("/contact")
                ? "py-4 md:text-lg delay-0 duration-150 ease-[ease] transition-colors relative before:absolute before:bottom-0 before:left-0 before:w-full before:h-[2px] before:bg-primary-color"
                : "py-4 md:text-lg delay-0 duration-150 ease-[ease] transition-colors"
            }
            onClick={() => setMenuOpen(false)}
          >
            Contact Us
          </Link>

          <Link
            to="/nysc"
            className={
              isActive("/nysc")
                ? "py-4 md:text-lg delay-0 duration-150 ease-[ease] transition-colors relative before:absolute before:bottom-0 before:left-0 before:w-full before:h-[2px] before:bg-primary-color"
                : "py-4 md:text-lg delay-0 duration-150 ease-[ease] transition-colors"
            }
            onClick={() => setMenuOpen(false)}
          >
            Nysc
          </Link>
          <Link
            to="/sponsor"
            className={
              isActive("/sponsor")
                ? "py-4 md:text-lg delay-0 duration-150 ease-[ease] transition-colors relative before:absolute before:bottom-0 before:left-0 before:w-full before:h-[2px] before:bg-primary-color"
                : "py-4 md:text-lg delay-0 duration-150 ease-[ease] transition-colors"
            }
            onClick={() => setMenuOpen(false)}
          >
            Sponsor
          </Link>
          <Link
            to="/projects"
            className={
              isActive("/contact")
                ? "md:text-xs text-[#D15242] hover:text-primary-color delay-0 duration-150 ease-[ease] transition-colors"
                : "md:text-xs text-black hover:text-primary-color delay-0 duration-150 ease-[ease] transition-colors"
            }
            onClick={() => setMenuOpen(false)}
          >
            Projects
          </Link>
          {user?.is_instructor && (
            <Link
              to="/instructors-upload"
              className={
                isActive("/instructors-upload")
                  ? "md:text-xs text-[#D15242] hover:text-primary-color delay-0 duration-150 ease-[ease] transition-colors"
                  : "md:text-xs text-black hover:text-primary-color delay-0 duration-150 ease-[ease] transition-colors"
              }
              onClick={() => setMenuOpen(false)}
            >
              Assignment
            </Link>
          )}
          {loggedIn ? (
            <div className="flex items-center">
              <div className="flex items-center gap-1 pr-2 hover:text-primary-color delay-0 duration-150 ease-in transition-colors">
                <div
                  style={{
                    cursor: "pointer",
                  }}
                >
                  <img
                    src={user?.profile_img || ""}
                    style={{
                      height: 35,
                      width: 35,
                      objectFit: "cover",
                      borderRadius: "50%",
                    }}
                  />
                </div>
                <Link to="/profile">{user?.firstname}</Link>
              </div>
              <div
                style={{
                  marginTop: 20,
                }}
                className="border-l-[1px] border-l-solid border-l-white pl-2 hover:text-primary-color delay-0 duration-150 ease-in transition-colors cursor-pointer"
              >
                <button
                  className={
                    navbar
                      ? "h-4 bg-[#d15242] text-white p-4 rounded-md  text-xs flex items-center justify-center  hover:bg-black hover:text-white"
                      : "h-4 bg-[#d15242] text-white p-4 rounded-md  text-xs flex items-center justify-center  hover:bg-[#f7f7f7] hover:text-black"
                  }
                  onClick={handleLogout}
                >
                  Log out
                </button>
              </div>
            </div>
          ) : location.pathname != "/nysc" ? (
            <div
              style={{
                marginTop: 20,
              }}
              className="flex items-center gap-2"
            >
              {" "}
              <button
                onClick={() => {
                  navigate("/sign-in");
                }}
                className="h-4 bg-white p-4 rounded-md  text-xs flex items-center justify-center hover:bg-[#d15242] hover:text-white"
              >
                Login
              </button>
            </div>
          ) : (
            <div
              style={{
                marginTop: 20,
              }}
              className="flex items-center gap-2"
            >
              <button
                onClick={() => {
                  navigate("/sign-in");
                }}
                className="h-4 bg-white p-4 rounded-md  text-xs flex items-center justify-center hover:bg-[#d15242] hover:text-white"
              >
                Login
              </button>
              <button
                onClick={() => {
                  navigate("/sign-up");
                }}
                className="h-4 bg-[#d15242] text-white p-4 rounded-md  text-xs flex items-center justify-center  hover:bg-white hover:text-black"
              >
                Register
              </button>
            </div>
          )}
        </nav>
      </div>
    </header>
  );
}
