import { keyframes } from "@mui/material";
import { useNavigate } from "react-router-dom";
import NYSCOFFER from "../../assets/images/nyscoffers.jpeg";
import Animator from "../../components/Aminator/Animator";
import { useIsMobile } from "../../components/hooks/useIsMobile";
import MainLayouts from "../../layout/MainLayouts";
import Acheivement from "./Component/Acheivements";
import ChooseUs from "./Component/ChooseUs";
import CoursesSection from "./Component/CoursesSection";
import Header from "./Component/Header";
import { default as LiveCoursesSection } from "./Component/LiveCoursesSection";
import { Typewriter } from "react-simple-typewriter";
function LandingPage() {
  const mobile = useIsMobile();
  const navigate = useNavigate();
  const bounceAnimation = keyframes`
  0%, 100% {
    transform: bottom: "30%",;
  }
  50% {
    transform: bottom: "50%",;
  }
`;

  return (
    <MainLayouts>
      <Header navigate={navigate} />
      <CoursesSection navigate={navigate} />
      <div
        style={{
          minHeight: "40vh",
          background: `url(${NYSCOFFER})`,
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          position: "relative",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            position: "absolute",
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            background: "#000000B2",
          }}
        />
        <div
          style={{
            width: mobile ? "100%" : "70%",
            // position: "relative",
            zIndex: 1000,
            color: "#fff",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            textAlign: "center",
          }}
        >
          <Animator delay={500} variant="fade">
            <h1
              style={{
                fontSize: mobile ? 36 : 48,
                fontWeight: 600,
                letterSpacing: -0.5,
                textShadow: "0px 4px 8px rgba(0, 0, 0, 0.3)",
              }}
            >
              {" "}
              <Typewriter
                words={[
                  "Are you currently serving as a NYSC corp member? This is for you!",
                ]}
              />
            </h1>
          </Animator>
          <button
            onClick={() => {
              navigate("/nysc");
            }}
            className="h-5 mt-7 bg-[#d15242] text-white py-6 px-12 rounded-md  text-xs flex items-center justify-center  hover:bg-white hover:text-black"
          >
            Enroll For This Offer
          </button>
        </div>
      </div>
      <LiveCoursesSection navigate={navigate} />
      <Acheivement navigate={navigate} />
      <ChooseUs />
    </MainLayouts>
  );
}
export default LandingPage;
